<template>
  <CCard
    class="yado-article border-white px-0"
    v-if="isArticalFacility && articlesFacilityList.length>0"
  >

    <CCardHeader
      borderColor="white"
      class="pb-0 px-0"
    >
      <CCardTitle class="cards-title card-h">この宿の記事</CCardTitle>
    </CCardHeader>
    <CCardBody class="px-0">
      <section class="article-card-group">
        <!-- :name="slideLeft?'slid-left':'slid-right'" -->
        <transition-group
          name="fades"
          type="transition"
          tag="ul"
          class="article-card"
        >
          <li
            class="border-white"
            v-for="hotel in ArticleInfoList"
            :key="hotel.id"
            @click="goArticlePage(hotel.url)"
          >
            <div
              class="top-section"
              v-lazy:background-image="hotel.thumnailUrl"
            ></div>
            <!-- :style="{'background-image':'url(' +ImgUrl +hotel.thumnail+'})'}" -->
            <div class="name-section">
              <strong>{{hotel.title | wordLimit(24, '...')}}</strong>
            </div>
          </li>
        </transition-group>
        <CButton
          v-if="ArtPageStart>1"
          @click="preArticle()"
          class="nav-arrow-prev"
        ><i class="bi bi-chevron-left"></i></CButton>
        <CButton
          class="nav-arrow-next"
          v-if="ArtPageStart<lastPage"
          @click="nextArticle()"
        ><i class="bi bi-chevron-right"></i></CButton>
      </section>

    </CCardBody>
  </CCard>
</template>

<script>
import axios from "axios";
// import i18n from "../i18n";

export default {
  name: "ArticleFacility",
  data() {
    return {
      isArticalFacility: false,
      articlesFacilityList: [],
      articlesFacilityListNav: [],
      UniqueId: "",
      ArtPageStart: 1,
      ArtPageLimit: 4,
      slideLeft: false,
    };
  },

  created() {
    this.UniqueId = this.$route.params.uniqueId;
  },
  computed: {
    ArticleInfoList() {
      var PageStart = (this.ArtPageStart - 1) * this.ArtPageLimit;
      var PageEnd = PageStart + this.ArtPageLimit;
      console.log(PageStart);
      console.log(PageEnd);
      return this.articlesFacilityList.slice(PageStart, PageEnd);
    },
    lastPage() {
      var totalLength = this.articlesFacilityList.length;
      return Math.ceil(totalLength / this.ArtPageLimit);
    },
  },

  methods: {
    nextArticle() {
      if (this.ArtPageStart < this.lastPage) {
        this.ArtPageStart++;
        this.slideLeft = true;
      }
    },
    preArticle() {
      if (this.ArtPageStart > 1) {
        this.ArtPageStart--;
        this.slideLeft = false;
      }
    },
    /**
     * get all Properties list
     */
    getAllProperties() {
      var urlRequest = `/rest/hotel/${this.UniqueId}/article`;
      axios
        .get(urlRequest, {
          headers: { "Accept-Language": this.$i18n.locale },
        })
        .then((resp) => {
          // console.log("Artical Facility" + JSON.stringify(resp.data));
          var dataList = resp.data.articles;
          this.articlesFacilityList = dataList.filter(a=> !a.url.match(/\?/));
          this.isArticalFacility = true;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    /** go to hotel detail page */
    goArticlePage(url) {
      document.location = url;
    },
  },
  beforeMount() {
    this.getAllProperties();
    // this.articleListNav(this.ArtPageStart, this.ArtPageLimit);
  },
};
</script>
<style lang="scss">
.yado-article {
  .article-card-group {
    position: relative;
    .nav-arrow-prev,
    .nav-arrow-next {
      position: absolute;
      top: 72px;
      padding: 0;

      i {
        display: block;
        padding: 0;
        margin: 0;
        font-size: 2em;
        font-weight: bold;
        line-height: 1em;
        color: #636f83;
        border: 2px solid #636f83;
        height: 35px;
        width: 35px;
        border-radius: 50%;
      }
    }
    .nav-arrow-prev {
      left: -40px;
      i {
        text-align: left !important;
      }
    }
    .nav-arrow-next {
      right: -40px;
      i {
        text-align: right !important;
      }
    }
    .article-card {
      display: flex;
      flex-flow: row wrap;
      overflow: hidden;
      max-height: 235px;

      li {
        width: 23.5%;
        border: 1px solid #d8dbe0;
        border-radius: 0.25rem;
        cursor: pointer;
        margin: 0 0.75% 2%;

        .top-section {
          width: 100%;
          padding-top: 56.25%;
          background-position: center;
          background-size: cover;
          position: relative;
        }

        // &:first-child {
        //   margin-left: 0;
        // }

        // &:last-child {
        //   margin-right: 0;
        // }

        .name-section {
          padding: 0.5rem 1rem;

          strong {
            font-size: 1em;
          }
        }
      }
    }
  }
  // .slid-left-leave-active,
  // .slid-left-enter-active {
  //   transition: all 0.5s ease;
  //   transform: translate(-1100px, 0);
  // }
  // .slid-left-enter {
  //   transform: translate(1100px, 0);
  // }
  // .slid-left-leave-to {
  //   transform: translate(-1100px, 0);
  // }

  // .slid-right-leave-active,
  // .slid-right-enter-active {
  //   transition: all 0.5s ease;
  //   transform: translate(1100px, 0);
  // }
  // .slid-right-enter {
  //   transform: translate(-1100px, 0);
  // }
  // .slid-right-leave-to {
  //   transform: translate(1100px, 0);
  // }

  // ///

  // .slide-fade-enter-active {
  //   transition: all 0.3s ease;
  // }
  // .slide-fade-leave-active {
  //   transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
  // }
  // .slide-fade-enter, .slide-fade-leave-to
  // /* .slide-fade-leave-active below version 2.1.8 */ {
  //   transform: translateX(1000px);
  //   opacity: 0;
  // }

  //@at-root
  .fades-enter-active,
  .fades-leave-active {
    transition: all 0.5s;
  }
  .fades-enter,
  .fades-leave-to {
    opacity: 0;
  }

  .fades-enter-active {
    transition-delay: 0.5s;
  }
}
</style>