<template>
  <div class="filter-content-section">
    <header class="filter-header">

      <CRow>
        <CCol md="3">
          <div class="datepicker-section">
            <div
              class="select-field"
              @click="selectDateModal = true"
            >
              <i class="bi bi-calendar"></i>
              <span>{{currentDate | formatDate}}</span>
            </div>

            <template v-if="selectDateModal">
              <div class="modal-content-section border-white border">

                <section class="calendar-selection">

                  <Calendar
                    class="custom-calendar max-w-full"
                    :locale="$i18n.locale"
                    :masks="masks"
                    :attributes="attributes"
                    disable-page-swipe
                    is-expanded
                    :columns="$screens({ default: 2, lg: 2, xl:2 })"
                  >
                    <template v-slot:day-content="{ day, attributes }">
                      <div
                        class="flex flex-col h-full z-10 overflow-hidden date-cell h-100"
                        @click="selectDateD(day)"
                      >
                        <span class="date-list">{{ day.day }}</span>
                        <div class="attr-section">
                          <span
                            v-for="attr in attributes"
                            :key="attr.key"
                            class=""
                            :class="attr.customData.class"
                          >
                            {{ attr.customData.title }}
                          </span>
                        </div>
                      </div>
                    </template>
                  </Calendar>
                </section>
              </div>
              <div
                class="overlayClose"
                @click="selectDateModal = false"
              ></div>
            </template>
          </div>
        </CCol>
        <CCol>
          <CSelect
            class="ml-2"
            :value.sync="filter.night"
            :options="['日帰り','1泊','2泊','3泊','4泊','5泊','6泊','7泊','8泊','9泊']"
          />
        </CCol>
        <CCol>
          <CSelect
            class="ml-2"
            :value.sync="filter.night"
            :options="['部室','1室','2室','3室','4室','5室','6室','7室','8室','9室']"
          />
        </CCol>
        <CCol md="4">
          <div class="select-person-section">
            <div
              class="select-field"
              @click="selectPersonsModal = true"
            >
              <i class="bi bi-person-fill"></i>
              <ul class="ul-line-block d-inline">
                <li>大人：{{filter.person.adult}}名</li>
                <li>子供：{{filter.person.child}}名</li>
              </ul>
            </div>
            <template v-if="selectPersonsModal">
              <div class="modal-content-section border-white border">
                <CRow class="border-bottom pb-2 m-0">
                  <CCol>
                    <strong>男性</strong>
                  </CCol>
                  <CCol>
                    <ul class="ul-line-block">
                      <li>
                        <CButton
                          color="info"
                          size="sm"
                          class="py-0"
                          @click="filter.person.adult = IncrementUpdate(filter.person.adult)"
                        >+</CButton>
                      </li>
                      <li><strong>{{filter.person.adult}}名</strong></li>
                      <li>
                        <CButton
                          color="info"
                          size="sm"
                          class="py-0"
                          @click="filter.person.adult = Decrementupdate(filter.person.adult)"
                        >-</CButton>
                      </li>
                    </ul>
                  </CCol>
                </CRow>
                <CRow class="pt-1 m-0">
                  <CCol>
                    <strong>子供</strong>
                  </CCol>
                  <CCol>
                    <ul class="ul-line-block">
                      <li>
                        <CButton
                          color="info"
                          size="sm"
                          class="py-0"
                          @click="filter.person.child = IncrementUpdate(filter.person.child)"
                        >+</CButton>
                      </li>
                      <li><strong>{{filter.person.child}}名</strong></li>
                      <li>
                        <CButton
                          color="info"
                          size="sm"
                          class="py-0"
                          @click="filter.person.child = Decrementupdate(filter.person.child)"
                        >-</CButton>
                      </li>
                    </ul>
                  </CCol>
                </CRow>

              </div>
              <div
                class="overlayClose"
                @click="selectPersonsModal = false"
              ></div>
            </template>
          </div>

        </CCol>
        <CCol md="2">
          <CButton
            block
            color="orange"
            class="search-btn"
          >検索</CButton>
        </CCol>
      </CRow>
    </header>
    <section class="room-filter-list">
      <CListGroup>
        <CListGroupItem
          class="px-0"
          v-for="n in 3"
          :key="n"
        >
          <CRow class="mx-0">
            <CCol
              md="2"
              class="px-0"
            >
              <img
              class="w-100"
                src="@/assets/images/sample.jpg"
              />
            </CCol>
            <CCol>
              <h5 class="room-title"><a href="#">【朝食付き】伊豆北川温泉 望水で波音を聞きながら＆水平線に昇る朝日を眺めながら温泉入浴</a></h5>
              <ul class="ul-line-block room-services-list">
                <li>
                  <CIcon name="cil-restaurant" />
                </li>
                <li>朝食事</li>
                <li>夕食</li>
              </ul>
            </CCol>
            <CCol md="2">
              <p class="basic-price">1名<strong class="text-danger">11,364円～</strong></p>
              <p class="total-price">税込12,500円～</p>
            </CCol>
            <CCol md="2">
              <CButton
                block
                color="info"
                variant="outline"
              >詳細・宿泊予約</CButton>
            </CCol>
          </CRow>
        </CListGroupItem>
      </CListGroup>
      <CButton
        color="dark"
        shape='pill'
        size="sm"
        class="px-5 mt-3 d-table mx-auto"
      >他のプランを見る
        <CIcon name="cil-plus" />
      </CButton>
    </section>

  </div>

</template>

<script>
// import axios from "axios";

export default {
  // props: {
  //   isComClicked: {
  //     type: Boolean,
  //   },
  //   activeId: {
  //     type: Number,
  //   },
  // },
  components: {},
  data() {
    const month = new Date().getMonth();
    const year = new Date().getFullYear();
    return {
      selectDateModal: false,
      selectPersonsModal: false,
      filter: {
        room: 1,
        night: 1,
        date: "",
        person: {
          adult: 1,
          child: 1,
        },
      },
      currentDate: new Date(),
      masks: {
        weekdays: "WWW",
      },
      attributes: [
        {
          key: 1,
          customData: {
            title: "10,000~",
            class: "",
          },
          dates: new Date(year, month, 1),
        },
        {
          key: 2,
          customData: {
            title: "12,000~",
            class: "",
          },
          dates: new Date(year, month, 2),
        },
        {
          key: 3,
          customData: {
            title: "12,000~",
            class: "",
          },
          dates: new Date(year, month, 5),
        },
        {
          key: 4,
          customData: {
            title: "12,000~",
            class: "",
          },
          dates: new Date(year, month, 7),
        },
        {
          key: 5,
          customData: {
            title: "14,000~",
            class: "",
          },
          dates: new Date(year, month, 11),
        },
        {
          key: 6,
          customData: {
            title: "12,000~",
            class: "",
          },
          dates: { months: 5, ordinalWeekdays: { 2: 1 } },
        },
        {
          key: 7,
          customData: {
            title: "12,000~",
            class: "",
          },
          dates: new Date(year, month, 22),
        },
        {
          key: 8,
          customData: {
            title: "12,000~",
            class: "",
          },
          dates: new Date(year, month, 25),
        },
      ],
    };
  },
  created() {},
  beforeMount() {},
  methods: {
    /** select date from calendar */
    selectDateD(date) {
      console.log(JSON.stringify(date.date));
      this.currentDate = date.date;
      this.selectDateModal = false;
    },
    /** Increment button */
    IncrementUpdate(field) {
      var maxLimit = 15;
      return field < maxLimit ? field + 1 : field;
    },

    /** Decrementupdate button */
    Decrementupdate(field) {
      var minLimit = 0;
      return field > minLimit ? field - 1 : field;
    },
  },
};
</script>
<style lang="scss" >
// orange color #d9481c
.filter-header {
  background: #f1f1f1;
  border: 1px solid #d8dbe0;
  border-radius: 5px;
  padding: 1rem 1.5rem;
}
.select-field {
  background: #ffffff;
  border: 1px solid #d8dbe0;
  border-radius: 0.25rem;
  margin-top: 2px;
  padding: 0 0.75rem;
  height: calc(1.5em + 0.75rem + 2px);
  color: #768192;
  line-height: 1.5;
  cursor: pointer;
  overflow: hidden;
}
.search-btn {
  margin: 2px 0 0 0;
}
.filter-content-section {
  .bi {
    font-size: 1.4em;
    color: #d9481c;
    text-align: left;
    margin-right: 1rem;
  }
}

.room-filter-list {
  margin: 2rem 0 0;
  .list-group {
    .list-group-item {
      border-left: none;
      border-right: none;
    }
  }
}

.room-title {
  font-weight: bold;
  a {
    color: #333333;
    text-decoration: none;
  }
}
.basic-price {
  margin: 0;
  text-align: right;
  strong {
    font-size: 1.2em;
    margin-left: 10px;
  }
}
.total-price {
  text-align: right;
}
.room-services-list {
  li {
    font-size: 0.85em;
  }
}

.datepicker-section,
.select-person-section {
  position: relative;
  .modal-content-section {
    position: absolute;
    background: #ffffff;
    display: block;
    z-index: 9;
    overflow-y: auto;
    padding: 1rem;
  }
}
.datepicker-section {
  .modal-content-section {
    width: 900px;
  }
}
.select-person-section {
  .modal-content-section {
    width: 100%;
  }
}
.overlayClose {
  // background: pink;
  width: 100%;
  height: 100%;
  overflow: auto;
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 8;
}
// calendar section start---------------

.vc-pane {
  // border: 1px solid red;
  // margin: 1px;
  border-left: 2px solid #cccccc;
  &:first-child {
    border: none;
  }
}
.date-cell {
  .attr-section {
    span {
      font-size: 0.9em;
      color: #008dde;
    }
  }
}
.calendar-selection {
  .submit_form {
    padding-bottom: 6rem;
  }
  // type style
  // .select-bg-type {
  //   width: 30px;
  //   height: 30px;
  //   margin: 4px;
  //   margin-right: 1rem;
  // }

  ::-webkit-scrollbar {
    width: 0px;
  }
  ::-webkit-scrollbar-track {
    display: none;
  }

  .custom-calendar.vc-container {
    // --day-border: 1px solid #b8c2cc;
    // --day-border-highlight: 1px solid #b8c2cc;
    // --day-width: 90px;
    --day-height: 50px;
    --weekday-bg: #f8fafc;
    --weekday-border: 1px solid #e4e2e2;
    border-radius: 0;
    width: 100%;
    & .vc-header {
      background-color: #f1f5f8;
      padding: 10px 0;
    }
    & .vc-weeks {
      padding: 0;
    }
    & .vc-weekday {
      background-color: var(--weekday-bg);
      // border-bottom: var(--weekday-border);
      // border-top: var(--weekday-border);
      padding: 5px 0;
    }
    & .vc-day {
      padding: 0 5px 3px 5px;
      text-align: center;
      height: var(--day-height);
      min-width: var(--day-width);
      border-bottom: var(--weekday-border);
      border-top: var(--weekday-border);
      background-color: white;
      &:hover {
        background: #e6edf3;
        cursor: pointer;
      }
      &.weekday-1 {
        // background-color: #fadddd;
        color: #ff4d4d;
      }
      &.weekday-7 {
        // background-color: #feefd0;
        color: #008dde;
      }
      &:not(.on-bottom) {
        border-bottom: var(--day-border);
        &.weekday-1 {
          border-bottom: var(--day-border-highlight);
        }
      }
      &:not(.on-right) {
        border-right: var(--day-border);
      }
    }
    & .vc-day-dots {
      margin-bottom: 5px;
    }
  }

  // color picker start
  .color-picker-table {
    td,
    th {
      vertical-align: middle;
    }
  }

  .color-code {
    padding: 10px 0px 0px 10px;
    float: left;
  }

  .vue-swatches {
    float: left;
  }

  .overlay-table {
    .vue-swatches {
      float: none;
    }
  }
}
</style>