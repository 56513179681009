<template>

  <div class="access_info">
    <h2 class="cards-title card-h mb-3">{{$t('facilityNav.access')}}</h2>

    <CRow
      UniqueId
      v-if="editData.address"
    >
      <CCol>
        <div><strong class="form_lable">{{
            $t("yadoEdit.accessForm.address")
          }} : </strong><span>{{editData.address}}</span></div>
        <div> <strong>TEL: </strong><span>{{editData.tel}}</span>
        </div>
      </CCol>
    </CRow>
    <CRow UniqueId>
      <CCol>
        <GmapMap
          :center="{ lat: editData.latitude, lng: editData.longitude }"
          :zoom="gmapMap.zoom"
          :options="gmapMap.options"
          ref="map"
          style="width: 100%; height: 300px"
          class="googleMap"
        >
          <!-- @dragend="onDragEnd"
                      style="width: 500px; height: 300px"
                      
                       -->

          <GmapInfoWindow
            :options="gmapInfoWindow.infoOptions"
            :position="gmapInfoWindow.infoWindowPos"
            :opened="gmapInfoWindow.infoWinOpen"
            @closeclick="gmapInfoWindow.infoWinOpen = false"
          >
            {{ editData.name }}<br>
            {{ editData.address }}<br>
          </GmapInfoWindow>
          <GmapMarker
            :key="index"
            v-for="(m, index) in markers"
            :position="m.position"
            :clickable="true"
            :draggable="false"
            @click="onMarkerClicked(m)"
          />
        </GmapMap>
      </CCol>
    </CRow>
    <CRow
      UniqueId
      class="py-2 px-0 mx-2 border-bottom-dashed"
      v-if="editData.guide"
    >
      <CCol
        md="2"
        class="px-0"
      ><strong class="form_lable">{{
            $t("yadoEdit.accessForm.guide")
          }}</strong>
      </CCol>
      <CCol>
        <div
          v-html="editData.guide"
          class="white_space"
        />
      </CCol>
    </CRow>
    <CRow
      class="py-2 px-0 mx-2 border-bottom-dashed"
      v-if="editData.parkingInfo"
    >
      <CCol
        md="2"
        class="px-0"
      >
        <strong class="form_lable">{{
            $t("yadoEdit.accessForm.parkingInfo")
          }}</strong>
      </CCol>
      <CCol>
        <div
          v-html="editData.parkingInfo"
          class="white_space"
        />
      </CCol>
    </CRow>
    <CRow
      class="py-2 px-0 mx-2 border-bottom-dashed"
      v-if="editData.pickupInfo"
    >
      <CCol
        md="2"
        class="px-0"
      ><strong class="form_lable">{{
            $t("yadoEdit.accessForm.pickupInfo")
          }}</strong></CCol>
      <CCol>
        <div
          v-html="editData.pickupInfo"
          class="white_space"
        />
      </CCol>
    </CRow>
  </div>
</template>

<script>
import axios from "axios";
import PROPERTY from "@/mixins/property";
import config from "@/config"

export default {
  name: "AccessView",
  data() {
    return {
      loading: false,
      openFgList: false,
      halfModal: false,
      openSlide: false,
      openImageGallery: false,

      facility: {},
      /** 表示・編集データ */
      editData: {
        name: "",
        address: "",
        tel: "",
        latitude: 0,
        longitude: 0,
        guide: "",
        parkingInfo: "",
        pickupInfo: "",
      },
      properties: [
        {
          id: "",
          name: "",
          description: "",
        },
      ],
      allAmenityList: [],
      allFeatureList: [],
      facilityContent: [],
      topicsInfo: {},
      allImages: [],
      roomInfo: {},
      allTags: [],
      imgUrl: config.IMAGE_URL,
      /** GmapMap設定 */
      gmapMap: {
        zoom: 14,
        options: {
          streetViewControl: false,
          fullscreenControl: false,
          mapTypeControl: false,
        },
      },
      /** GmapInfoWindow設定 */
      gmapInfoWindow: {
        infoOptions: {
          pixelOffset: {
            width: 0,
            height: -35,
          },
        },
        infoWindowPos: null,
        infoWinOpen: false,
      },
      categoryFeatures: [],
      test: [],
      openProperty: 0,
      contentType: "",
      reload: 0,
    };
  },
  mixins: [PROPERTY],

  computed: {
    /** マーカーリスト */
    markers() {
      const marker = {
        position: { lat: this.editData.latitude, lng: this.editData.longitude },
      };
      return [marker];
    },
  },
  created() {
    this.UniqueId = this.$route.params.uniqueId;
  },
  methods: {
    /**
     * get faciliy information by id
     */
    getFacilityInformation() {
      var urlRequest = "/rest/hotel/" + this.UniqueId;
      axios
        .get(urlRequest, {
          headers: { "Accept-Language": this.$i18n.locale },
        })
        .then((resp) => {
          console.log(JSON.stringify(resp.data));
          this.facility = resp.data;
          this.refreshLocation();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    /**
     * get all selected Properties list
     */
    getAllPropertyInfo() {
      var urlRequest = "/rest/hotel/" + this.UniqueId + "/property";
      axios
        .get(urlRequest, {
          headers: { "Accept-Language": this.$i18n.locale },
        })
        .then((resp) => {
          this.properties = resp.data.properties;
          this.refreshProperty();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //#region ----- event -----

    /** 地図ドラッグ終了 */
    // onDragEnd() {
    //   this.setLatLng();
    // },

    // /** マーカークリック */
    onMarkerClicked(marker) {
      this.toggleMarkerBubble(marker);
    },

    /** マーカー情報表示を切替 */
    toggleMarkerBubble(marker) {
      this.gmapInfoWindow.infoWindowPos = marker.position;
      this.gmapInfoWindow.infoWinOpen = true;
    },

    /**
     * 宿ロケーションをAPIから取得したデータに更新
     */
    refreshLocation() {
      this.editData.name = this.facility.name;
      this.editData.address = this.facility.address1;
      // this.editData.address = this.facility.address1 + this.facility.address2;
      this.editData.tel = this.facility.telephone;

      if (this.facility.latitude == null || this.facility.longitude == null) {
        // APIから取得した緯度経度がnullの場合、住所から緯度経度を取得
        this.moveMapToAddress();
        return;
      }

      this.editData.latitude = this.facility.latitude;
      this.editData.longitude = this.facility.longitude;
    },

    /**
     * 宿プロパティをAPIから取得したデータに更新
     */
    refreshProperty() {
      const getValue = (id) =>
        this.properties.find((a) => a.propertyId == id).description;

      this.editData.guide = getValue(this.PROPERTY.GUIDE_ID);
      this.editData.parkingInfo = getValue(this.PROPERTY.PARKING_INFO_ID);
      this.editData.pickupInfo = getValue(this.PROPERTY.PICKUP_INFO_ID);
    },

  },
  beforeMount() {
    // this.deleteMe();
    this.getFacilityInformation();
    this.getAllPropertyInfo();
  },
};
</script>
<style scoped>
.row {
  margin-left: 0;
  margin-right: 0;
}
</style>

