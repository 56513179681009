<template>
  <div
    class="facility_page"
    :class="openImageGallery?'open-modal':'close-modal'"
  >
    <FacilityHeader />
    <PreLoading v-if="!isLoadFaciltiy" />
    <div
      class="page-content"
      v-if="ifYado && (pageStatus == PAGE_STATUS.VISIBLE || pageStatus == PAGE_STATUS.DRAFT)"
    >

      <!-- <div class="page-content"> -->
      <CRow class=" d-sm-down-none">
        <CCol>

          <nav class="f-breadcrumb my-2">
            <ol>
              <li
                v-for="(crumb, ci) in breadcrumb"
                :key="ci"
              >
                <a
                  :class="crumb.order==100 ? 'active-page':'btn-link'"
                  @click="crumb.order==100 ? null : openAreaL(crumb)"
                >
                  <!-- :class="{ disabled: isLast(ci) }" -->
                  {{ crumb.name }}
                </a>
              </li>
            </ol>
          </nav>
        </CCol>

      </CRow>
      <!-- end breadcrumb -->
      <section class="body_section">
        <CCard
          class="yado-intro-content border-white"
          v-if="isMainfacility"
        >
          <CCardHeader
            borderColor="white"
            class="pb-0 px-0"
          >
            <CCardTitle class="cards-title px-0">
              <div class="title-favorit">
                <div class="title-section">
                  <h4>{{facility.name}}</h4>
                  <span class="address_info">{{addressInfo}}</span>
                </div>

                <!-- <div class="favorit_section mx-0"><span class="favorite-btn">
                    <i class="icon cil-heart"></i>
                    <b>000</b>
                  </span></div> -->
              </div>

            </CCardTitle>

          </CCardHeader>
          <CCardBody class="px-0">

            <CRow class="mx-0 px-0">
              <CCol class="mx-0 px-0">
                <div class="intro-gallery d-md-down-none">
                  <div
                    class="left"
                    v-lazy:background-image="imgLg+intorImgFirst"
                  ></div>
                  <div class="right">
                    <template v-for="img in IntroImgList">
                      <!-- {{imgMd+img.fileName}} -->
                      <div
                        class="images"
                        :key="img.fileName"
                        v-lazy:background-image="imgMd+img.fileName"
                      ></div>
                    </template>
                  </div>
                </div>

                <ImageSlider
                  class="d-lg-none"
                  spSlider
                  :images="spIntroImg"
                  :key="reload"
                />

              </CCol>
            </CRow>
            <CRow class="mt-3 mx-0 px-0">
              <CCol
                md="10"
                class="mx-0 px-0"
              >
                <div
                  v-html="facility.desciption"
                  class="white_space px-2"
                ></div>
              </CCol>
              <CCol
                md="2"
                class="mt-3 mx-0"
              >
                <CButton
                  block
                  color="info"
                  variant="outline"
                  class="mb-3"
                  @click="openSlideGallery()"
                ><strong>{{$t('facilityNav.gallery')}}</strong></CButton>
                <CButton
                  v-if="yadoVersion>=2"
                  block
                  color="danger"
                ><strong>{{$t('common.reservation')}}</strong> </CButton>
              </CCol>
            </CRow>
          </CCardBody>

        </CCard>
        <!-- yado-intro-content end  -->

        <CCard
          class="yado-intro-content-skeleton skeleton border-white px-0"
          v-else
        >
          <CCardHeader
            borderColor="white"
            class="pb-0"
          >
            <CCardTitle class="cards-title px-0">
              <span
                class="skeleton-box s-f-header mb-2"
                style="width:60%; height:1.4em;"
              ></span>
              <span
                class="skeleton-box s-f-header mb-2"
                style="width:70%; height:1em;"
              ></span>

            </CCardTitle>

            <CCardSubtitle class="s-yado_sub_nav">
              <span
                class="skeleton-box"
                style="width:100%;  height:1.8em;"
              ></span>

            </CCardSubtitle>
          </CCardHeader>
          <CCardBody>

            <CRow class="mx-0 px-0">
              <CCol class="mx-0 px-0">
                <div class="intro-gallery d-md-down-none">
                  <CRow>
                    <CCol>
                      <span
                        class="skeleton-box"
                        style="width:100%;  height:98%"
                      ></span>
                    </CCol>
                    <CCol>
                      <CRow>
                        <CCol class="pb-1"><span
                            class="skeleton-box"
                            style="width:100%;  height:120px;"
                          ></span></CCol>
                        <CCol class="pb-1"><span
                            class="skeleton-box"
                            style="width:100%;  height:120px;"
                          ></span></CCol>
                      </CRow>
                      <CRow>
                        <CCol class="pt-1"><span
                            class="skeleton-box"
                            style="width:100%;  height:120px;"
                          ></span></CCol>
                        <CCol class="pt-1"><span
                            class="skeleton-box"
                            style="width:100%;  height:120px;"
                          ></span></CCol>
                      </CRow>
                    </CCol>
                  </CRow>
                </div>

                <span
                  class="skeleton-box d-lg-none"
                  style="width:100%;  height:130px;"
                ></span>

              </CCol>
            </CRow>
            <CRow class="mt-3 mx-0 px-0">
              <CCol
                md="10"
                class="mx-0 px-0"
              >
                <span
                  class="skeleton-box"
                  style="width:90%;  height:1.2em;"
                ></span>
                <span
                  class="skeleton-box"
                  style="width:95%;  height:1.2em;"
                ></span>
                <span
                  class="skeleton-box"
                  style="width:70%;  height:1.2em;"
                ></span>

              </CCol>
              <CCol
                md="2"
                class=" mx-0"
              >

                <span
                  class="skeleton-box"
                  style="width:100%;  height:2.2em;"
                ></span>
                <span
                  class="skeleton-box"
                  style="width:100%;  height:2.2em;"
                ></span>
              </CCol>
            </CRow>
          </CCardBody>

        </CCard>
        <!-- yado-intro-content-skeleton end -->

        <!--============ end yado intro ============== -->

        <ArticleFacility />
        <!-- end article card  -->
        <CCard
          class="yado-appeal border-white px-0"
          v-if="yadoVersion>99"
        >

          <CCardHeader
            borderColor="white"
            class="pb-0 px-0"
          >
            <CCardTitle class="cards-title card-h">アピール記事</CCardTitle>
          </CCardHeader>
          <CCardBody class="px-0">

            <ul class="article-card">
              <li
                class="border-white article-list"
                v-for="n in 4"
                :key="n"
              >
                <div
                  class="top-section"
                  :style="{'background-image':'url(https://kunozan.jp/news/wp-content/themes/post8/common/img/onyoku_ganban_img.jpg)'}"
                >
                  <span class="article-fav">
                    <i class="icon cil-heart"></i>
                  </span>
                </div>

                <div class="name-section">
                  <ul class="date_address d-lg-none">
                    <li class="date">2021.00.00</li>
                    <li class="ken"><strong>静岡県</strong></li>
                  </ul>
                  <h6>初夏なのにふぐが食べたくなったので、「大塚」に助けてもらった。</h6>
                  <span class="sp-fav d-lg-none">
                    <i class="icon cil-heart"></i>
                  </span>

                </div>
              </li>
            </ul>
          </CCardBody>
        </CCard>
        <!-- end appeal article  -->
        <CCard
          class="border-white plan-filter px-0"
          v-if="yadoVersion>=2"
        >
          <CCardHeader
            borderColor="white"
            class="pb-0 px-0"
          >
            <CCardTitle class="cards-title px-0">{{$t('common.facilityReservation')}}</CCardTitle>
          </CCardHeader>
          <CCardBody class="px-0">
            <FacilityPlanFilter />
          </CCardBody>
        </CCard>
        <!-- facility plan filter end  -->

        <CCard class="room-section border-white px-0">
          <CCardHeader
            borderColor="white"
            class="pb-0 px-0"
          >
            <strong class="cards-title card-h mb-3">
              {{$t('common.informaton')}}
            </strong>
          </CCardHeader>
          <CCardBody class="px-0">
            <template v-if="roomInfo && yadoVersion>=2">

              <strong
                class="cards-title mb-3"
                v-if="roomInfo.title"
              >{{$t('prepertyList.room')}}</strong>
              <div
                v-if="roomInfo.description"
                v-html="roomInfo.description"
                class="white_space mb-2 px-2"
              />

              <CCardGroup
                columns
                deck
                class="mb-3"
                v-if="roomInfo.images"
              >
                <CCard
                  class="border-white"
                  v-for="room in roomInfo.images"
                  :key="room.fileName"
                >
                  <span
                    class="single-image-box"
                    v-lazy:background-image="imgMd+room.fileName"
                  >
                  </span>

                </CCard>
              </CCardGroup>

              <section class="room-type-section mb-5">
                <h3 class="sub-card-title mb-4">{{$t('prepertyList.room')+ $t('allBasicOptions.type')}}</h3>
                <CRow
                  v-for="n in 5"
                  :key="n"
                  class="mx-1 py-3 border-top-dashed border-bottom-dashed"
                >
                  <CCol
                    md="2"
                    class="px-0"
                  >
                    <CImg
                      v-if="!$parent.isMobile"
                      fluid
                      src="https://kunozan.jp/news/wp-content/themes/post8/common/img/onyoku_ganban_img.jpg"
                    />
                    <section
                      class="room-type-list"
                      v-else
                    >
                      <div class="feature-section">
                        <CImg
                          fluid
                          src="https://kunozan.jp/news/wp-content/themes/post8/common/img/onyoku_ganban_img.jpg"
                        />
                      </div>
                      <div class="content-section">
                        <h6 class="type-name">部屋の名前</h6>
                        <div class="total-sum">
                          <span>1名<strong class="amount-sum ">13,000円~</strong></span>
                          <span>12,500円~</span>
                        </div>
                      </div>
                    </section>
                  </CCol>
                  <CCol
                    md="6"
                    class="mb-2"
                  >
                    <h6
                      class="type-name"
                      v-if="!$parent.isMobile"
                    >部屋の名前</h6>
                    <ul class="room-label">
                      <li><strong>定員</strong>6名</li>
                      <li><strong>広さ</strong>6畳</li>
                      <li><strong>ベッド</strong>セミダブル</li>
                    </ul>
                    <ul class="badge-section">
                      <li>
                        <CBadge class="border-secondary">禁止</CBadge>
                      </li>
                      <li>
                        <CBadge class="border-secondary">Wi-Fi利用可</CBadge>
                      </li>
                    </ul>
                  </CCol>
                  <CCol
                    md="2"
                    class="total-sum"
                    v-if="!$parent.isMobile"
                  >
                    <span>1名<strong class="amount-sum ">13,000円~</strong></span><br>
                    <span>12,500円~</span>
                  </CCol>
                  <CCol md="2">
                    <CButton
                      block
                      color="info"
                      size="sm"
                      variant="outline"
                    >{{$t('common.reservation')}}</CButton>
                  </CCol>
                </CRow>
              </section>
            </template>

            <div class="yado-category my-2">
              <ul class="category-card">
                <li
                  class="border-white"
                  v-for="facility in categoryFeatures"
                  :key="facility.id"
                  @click="openSlideContent(facility.propertyId)"
                >
                  <div class="name-section">
                    <strong>{{facility.property}}</strong>
                  </div>
                  <div
                    class="top-section"
                    v-lazy:background-image="facility.images.length>0 ? imgMd+facility.images[0].fileName: null"
                  ></div>
                </li>
              </ul>

            </div>
            <PropertyView />
          </CCardBody>
        </CCard>
        <!-- property section end  -->

        <CCard class="border-white access px-0">
          <!-- <CCardHeader
            borderColor="white"
            class="pb-0"
          >
            <CCardTitle class="cards-title px-0">{{facility.name}}</CCardTitle>

          </CCardHeader> -->
          <CCardBody class="px-0">
            <AccessViiew />

          </CCardBody>
        </CCard>
        <!-- access card end -->
        <!-- <SocialShare class="mb-3 " /> -->

        <!-- end appeal card  -->
        <CCard
          class="yado-recomand border-white px-0"
          v-if="yadoVersion>=2"
        >

          <CCardHeader
            borderColor="white"
            class="pb-0 px-0"
          >
            <CCardTitle class="cards-title card-h">おすすめの宿</CCardTitle>
          </CCardHeader>
          <CCardBody class="px-0">

            <ul class="article-card">
              <li
                class="border-white"
                v-for="n in 4"
                :key="n"
              >
                <div
                  class="top-section"
                  :style="{'background-image':'url(https://kunozan.jp/news/wp-content/themes/post8/common/img/town_terrace01.jpg)'}"
                ></div>
                <div class="name-section">
                  <strong>温泉だけじゃなく〇〇がある、箱根の穴場宿</strong>
                </div>
              </li>
            </ul>
          </CCardBody>
        </CCard>
        <!-- end recomand card  -->
        <NearbyFacility />
        <!-- end near card  -->
        <CCard class="tag-list-section border-white px-0">
          <CCardBody class="px-0">
            <ul class="tag-list">
              <li
                v-for="tag in allTags"
                :key="tag.name"
              >
                <CButton
                  size="sm"
                  variant="outline"
                  color="info"
                  @click="openTagL(tag)"
                >
                  <b># {{tag.name}}</b>
                </CButton>
              </li>
            </ul>
          </CCardBody>
        </CCard>
        <ImgaeGallery
          :isGalleryOpen="openImageGallery"
          @isOpen="openImageGallery=$event"
        />
        <SlideContent
          :isComClicked="openSlide"
          @isOpen="openSlide=$event"
          :activeId="openProperty"
          @returnId="openProperty = $event"
        />
      </section>
      <nav class="f-breadcrumb m-1">
        <ol>
          <li
            v-for="(crumb, ci) in breadcrumb"
            :key="ci"
          >
            <a
              :class="crumb.order==100 ? 'active-page':'btn-link'"
              @click="crumb.order==100 ? null : openAreaL(crumb)"
            >
              <!-- :class="{ disabled: isLast(ci) }" -->
              {{ crumb.name }}
            </a>
          </li>
        </ol>
      </nav>
    </div>
    <!-- page-content end  -->
    <div
      class="draft-body"
      v-if="pageStatus == PAGE_STATUS.DRAFT"
    >
    </div>
    <div
      class="not-found-page page-content pt-5 "
      v-if="pageStatus == PAGE_STATUS.INVISIBLE"
    >
      <CAlert
        color="danger"
        class="my-5"
      >
        データが存在しません
      </CAlert>

    </div>

    <FacilityFooter />

  </div>
  <!-- facility page end  -->
</template>

<script>
import axios from "axios";
import config from "@/config";
import i18n from "@/i18n";
import SlideContent from "@/components/SlideRightContent";
import ImgaeGallery from "@/components/ImageGallery";
import PropertyView from "@/components/PropertyView";
import AccessViiew from "@/components/AccessView";
import FacilityHeader from "@/containers/FacilityHeader";
import FacilityPlanFilter from "@/components/FacilityPlanFilter";
import ArticleFacility from "@/components/ArticleFacility";
import NearbyFacility from "@/components/NearbyFacility";
import FacilityFooter from "@/containers/FacilityFooter";
import PROPERTYLIST from "@/mixins/property";
import HOTEL_STATUS from "@/mixins/property";
import PAGE_STATUS from "@/mixins/property";

export default {
  mixins: [PROPERTYLIST, HOTEL_STATUS, PAGE_STATUS],
  name: "facilityDetails",
  components: {
    SlideContent,
    PropertyView,
    ImgaeGallery,
    AccessViiew,
    FacilityHeader,
    FacilityPlanFilter,
    ArticleFacility,
    NearbyFacility,
    FacilityFooter,
  },

  data() {
    return {
      isLoadFaciltiy: false,
      ifYado: false,
      noDataE: false,
      openFgList: false,
      halfModal: false,
      openSlide: false,
      openImageGallery: false,
      isMainfacility: false,
      pageStatus: "",
      langs: ["ja", "en", "zh"],
      breadcrumb: [],
      facility: {},
      /** 表示・編集データ */
      editData: {
        name: "",
        address: "",
        tel: "",
        latitude: 0,
        longitude: 0,
        guide: "",
        parkingInfo: "",
        pickupInfo: "",
      },
      properties: [
        {
          id: "",
          name: "",
          description: "",
        },
      ],
      allAmenityList: [],
      allFeatureList: [],
      facilityContent: [],
      topicsInfo: {},
      allImages: [],
      roomInfo: {
        title: "",
        description: "",
      },
      allTags: [],
      // ImgUrl: "https://picture.liberty-resort.net/files/",
      imgLg: config.IMAGE_LG_URL, // medium size
      imgMd: config.IMAGE_MD_URL, // medium size
      yadoVersion: config.VERSION, // medium size
      /** GmapMap設定 */
      gmapMap: {
        zoom: 14,
        options: {
          streetViewControl: false,
          fullscreenControl: false,
          mapTypeControl: false,
        },
      },
      /** GmapInfoWindow設定 */
      gmapInfoWindow: {
        infoOptions: {
          pixelOffset: {
            width: 0,
            height: -35,
          },
        },
        infoWindowPos: null,
        infoWinOpen: false,
      },
      categoryFeatures: [],
      IntroImg: [],
      spIntroImg: [],
      IntroImgList: [],
      openProperty: 0,
      intorImgFirst: "",
      contentType: "",
      reload: 0,
      testIm: "",
      test: [],
    };
  },

  computed: {
    isHis() {
      return window.history.length > 1 ? true : false;
    },
    addressInfo() {
      var address =
        "〒" +
        this.facility.zipcode +
        " " +
        (this.facility.address1 ? this.facility.address1 : "") +
        (this.facility.address2 ? " + " + this.facility.address2 : "");
      return address;
    },
  },
  created() {
    this.UniqueId = this.$route.params.uniqueId;
  },
  // mixins: [PROPERTY],
  methods: {
    openSlideContent(Id) {
      this.openSlide = false;
      this.openSlide = true;
      this.openProperty = Id;
    },
    openSlideGallery() {
      this.openImageGallery = false;
      this.openImageGallery = true;
    },

    /**
     * get faciliy information by id
     */
    getFacilityInformation() {
      this.isLoadFaciltiy = false;
      var urlRequest = "/rest/hotel/" + this.UniqueId;
      this.pageStatus = this.PAGE_STATUS.INVISIBLE; // TODO: hard coding

      axios
        .get(urlRequest, {
          headers: { "Accept-Language": this.$i18n.locale },
        })
        .then((resp) => {
          this.debugLog(resp.data);
          this.facility = resp.data;

          const published =
            (this.facility.status & this.HOTEL_STATUS.FLAG_CONTENT_VISIBLE) !==
            0;
          const key = `${this.facility.telephone}${this.facility.zipcode}`;

          this.pageStatus = this.PAGE_STATUS.VISIBLE; // TODO: hard coding

          if (!published && this.$route.query?.preview !== key) {
            // TODO: (1) 非公開宿でクエリ指定が誤っている場合は「データが存在しません」を表示する
            this.debugLog("非表示宿");
            this.pageStatus = this.PAGE_STATUS.INVISIBLE; // TODO: hard coding
            return;
          }

          if (!published && this.$route.query.preview === key) {
            // TODO: (2) Draft表示
            this.debugLog("Draft表示");
            this.pageStatus = this.PAGE_STATUS.DRAFT; // TODO: hard coding
          }

          // 宿情報表示
          this.debugLog("公開表示");
          this.ifYado = true;
          this.reload++;
          this.setBreadcrumb();
          this.isMainfacility = true;

          // TODO: beforeMountの処理をここに移す
          this.getAllPropertyInfo();
          // this.getAmenityAndFeatureInfo();
          this.getHotelContent();
          this.getAlltag();
          this.getPropertyImg();
          this.isLoadFaciltiy = true;
        })
        .catch((err) => {
          this.noDataE = true;
          console.log(err);
          this.pageStatus = this.PAGE_STATUS.INVISIBLE; // TODO: hard coding
          console.log(this.pageStatus);
          this.isLoadFaciltiy = true;
          // TODO: (3) 「データが存在しません」を表示する
        });
    },
    setBreadcrumb() {
      this.breadcrumb.push({
        name: "トップ",
        order: 0,
      });
      this.facility.locations.forEach((a, i) => {
        if (a.level <= 4) {
          this.breadcrumb.push({
            order: i + 1,
            name: a.name,
            areaId: a.id,
          });
        }
      });
      this.breadcrumb.push({ name: this.facility.name, order: 100 });
      this.breadcrumb.sort((a, b) => a.order - b.order);
    },
    /**
     * get all selected Properties list
     */
    getAllPropertyInfo() {
      var urlRequest = "/rest/hotel/" + this.UniqueId + "/property";
      axios
        .get(urlRequest, {
          headers: { "Accept-Language": this.$i18n.locale },
        })
        .then((resp) => {
          this.properties = resp.data.properties;
          // this.refreshProperty();
        })
        .catch((err) => {
          console.log(err);
        });
    },

    /**
     * get hotel details
     */
    getHotelContent() {
      var urlRequest = "/rest/hotel/" + this.UniqueId + "/detail";
      axios
        .get(urlRequest, {
          headers: { "Accept-Language": this.$i18n.locale },
        })
        .then((resp) => {
          // console.log("all DD::" + JSON.stringify(resp.data));
          // this.facilityContent = resp.data.details;
          var allList = resp.data.details;

          // only below selected properties are been shown
          var selectedList = [
            this.PROPERTYLIST.recomendation,
            this.PROPERTYLIST.room,
            this.PROPERTYLIST.bathAndOnsen,
            this.PROPERTYLIST.food,
            this.PROPERTYLIST.equipmentServices,
            this.PROPERTYLIST.others,
          ];

          // match with selected property
          allList.forEach((a) =>
            selectedList.forEach((l, k) => {
              if (
                a.propertyId == l &&
                (a.description !== "" ||
                  (a.images !== undefined && a.images.length > 0))
              ) {
                a.viewOrder = k;
                this.categoryFeatures.push(a);
              }
            })
          );
          // sort by order
          this.categoryFeatures.sort((a, b) => a.viewOrder - b.viewOrder);

          // this.categoryFeatures = this.facilityContent.filter(
          //   (e) =>
          //     // e.propertyId !== this.PROPERTYLIST.topics &&
          //     e.propertyId !== this.PROPERTYLIST.hotelOverview
          // );
          this.roomInfo = this.categoryFeatures.find(
            (e) => e.propertyId == this.PROPERTYLIST.room
          );
        })
        .catch((err) => {
          console.log(err);
        });
    },
    /**
     *  get all tag list
     *
     */
    getAlltag() {
      var urlRequest = "/rest/hotel/" + this.UniqueId + "/tag";
      axios
        .get(urlRequest, {
          headers: { "Accept-Language": this.$i18n.locale },
        })
        .then((resp) => {
          this.allTags = resp.data.tags;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    /**
     * get property images
     */
    getPropertyImg() {
      var urlRequest = "/rest/hotel/" + this.UniqueId + "/detail/24";
      axios
        .get(urlRequest, {
          headers: { "Accept-Language": this.$i18n.locale },
        })
        .then((resp) => {
          var imgD = resp.data.images;
          this.spIntroImg = imgD;
          this.reload++;
          console.log("intro image" + JSON.stringify(imgD));
          this.IntroImg = imgD.slice(0, 4);
          this.intorImgFirst = this.IntroImg[0].fileName
            ? this.IntroImg[0].fileName
            : "no-image";
          Array(5)
            .fill()
            .map((l, a) => {
              this.IntroImgList.push({
                fileName: imgD[a] ? imgD[a].fileName : "no-image" + a,
              });
            });
          this.IntroImgList = this.IntroImgList.slice(1);
        })
        .catch((err) => {
          console.log(err);
        });
    },

    errorImg() {
      return require("@/assets/images/error-image.png");
    },

    /**
     *  open facility page by breadcrumb area
     *
     */
    openAreaL(area) {
      if (area.order === 0) {
        this.$router.push({
          name: "FacilityListView",
        });
      } else {
        this.$router.push({
          name: "FacilityListView",
          // query: { type: area },
          query: { areaId: area.areaId, areaName: area.name },
        });
      }
    },
    /** open facility list by tag */
    openTagL(tag) {
      console.log(tag);
      this.$router.push({
        name: "FacilityListView",
        query: { tagId: tag.id, tagName: tag.name },
      });
    },

    scrollBrev(property) {
      let nodeId = "vue-hgt-" + this._uid;
      let style = document.getElementById(nodeId);
      if (!style) {
        style = document.createElement("style");
        style.id = nodeId;
        style.type = "text/css";
        this.$el.appendChild(style);
      }

      let cssClass = `body { overflow-y: ${property}  !important;} `;
      style.innerHTML = cssClass;
    },
    /**
     * go to xserver page */
    goXserver() {
      // window.location.href = "https://yado-sagashi.com/";
      window.location.href = config.WPSITE;
    },
    introImg(img) {
      img = img.replace("(", "%28");
      img = img.replace(")", "%29");
      return img;
    },
  },
  updated() {
    this.openImageGallery || this.openSlide
      ? this.scrollBrev("hidden")
      : this.scrollBrev("auto");
  },

  beforeMount() {
    this.getFacilityInformation();
  },
  metaInfo() {
    return {
      title: this.facility.name
        ? `${this.facility.name} - ${i18n.t("siteInfo.siteTitle")}`
        : `${i18n.t("siteInfo.siteTitle")}`,
      meta: [
        {
          name: "description",
          content: this.facility.desciption,
        },
        { property: "og:title", content: this.facility.name },
        {
          property: "og:site_name",
          content: `${i18n.t("siteInfo.siteTitle")}`,
        },
        {
          property: "og:description",
          content: this.facility.desciption,
        },
        {
          property: "og:image",
          content: config.IMAGE_SM_URL + this.intorImgFirst,
        },
      ],
    };
  },
};
</script>

<style lang="scss">
.body_section {
  text-align: left;
}
.single-image-box {
  width: 100%;
  padding-top: 56.25%;
  display: block;
  cursor: default;
  position: relative;
  background-size: cover;
  background-position: 50%;
  // transition: 0.7s ease;
}

.active-page {
  color: #676767 !important;
  cursor: default !important;
  text-decoration: none !important;
}
.open-modal {
  // display: none;
}
</style>