<template>
  <!-- <div :class="isGalleryOpen? ' fadeIn':'fadeOut'"> -->
  <!-- <transition name="fade"> -->
  <div
    class="opt-gallery-body"
    v-if="isGalleryOpen"
  >
    <CButton
      @click="togGalleryModal()"
      class="mt-2 d-lg-none"
    >
      <b class="arrow-left">&#x276E;</b>

    </CButton>

    <div class="modal-gallery">
      <CButton
        class="closeBtn d-sm-down-none"
        color="danger"
        @click="togGalleryModal()"
      >X</CButton>
      <CButton
        class="closeBtn-sm d-lg-none"
        color="danger"
        @click="togGalleryModal()"
      >X</CButton>
      <GalleryBody v-if="isGalleryOpen" />
      <!-- sp image gallery section end  -->

    </div>
  </div>
  <!-- </transition> -->
  <!-- </div> -->
</template>

<script>
import GalleryBody from "@/components/GalleryBody";

export default {
  props: {
    isGalleryOpen: {
      type: Boolean,
    },
  },
  name: "ImageGallerySlider",
  components: {
    GalleryBody
  },
  methods: {
    togGalleryModal() {
      this.$emit("isOpen", false);
    },
  },
};
</script>
<style scoped>
.side-nav {
  background: #cccccc;
}
.side-nav ul li {
  display: inline-block;
  padding: 0.5rem 2rem;
  font-weight: bold;
  height: 50px;
}
.closeBtn {
  position: fixed;
  right: 15px;
  top: 0;
  border-radius: 0px;
  z-index: 9;
  height: 50px;
  width: 50px;
  font-size: 2em;
  line-height: 0;
  margin: 0;
}
.closeBtn-sm {
  position: fixed;
  right: 0px;
  top: 0;
  border-radius: 0px;
  z-index: 9;
  height: 40px;
  width: 40px;
  font-size: 1.6em;
  line-height: 0;
  margin: 0;
  text-align: center;
}
.opt-gallery-body {
  height: 100%;
  width: 100%;
  background: #ffffff;
  overflow: auto;
}
.imgGallery-section .side-header {
  height: 50px;
}
.sp-img-gallery {
  clear: both;
  display: block;
  margin-top: 0;
}
.sp-img-gallery h4 {
  margin-left: 1%;
}
.sp-img-gallery .img-list ul {
  margin-bottom: 10px;
}
.sp-img-gallery .img-list ul li {
  display: inline-block;
  width: 31.33%;
  margin: 1%;
}
.sp-img-gallery img {
  width: 100%;
  height: auto;
}
.singleImgModal {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 99999999;
  background: #5f5f5f;
  width: 100%;
  height: 100%;
}
.overLay {
  padding: 1rem;
  top: 2rem;
  position: relative;
}
.sp_close {
  right: 0;
  top: 0;
  position: fixed;
  border-radius: 0;
}
</style>