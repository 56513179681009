<template>
  <div :class="isComClicked? ' fadeIn':'fadeOut'">
    <!-- <div> -->
    <transition name="slide-fade">
      <div
        class="half-view-modal"
        v-show="isComClicked"
      >
        <div class="modal-wrapper">

          <div class="modal-body-section">
            <CButton
              class="closeBtn"
              color="danger"
              @click="toggleModal()"
            >X</CButton>
            <PropertyContent
              :activeId="activeId"
              :isComClicked="isComClicked"
              v-if="isComClicked"
            />
          </div>
          <div
            class="outBox"
            @click="toggleModal()"
          ></div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import PropertyContent from "@/components/PropertyContent";

export default {
  props: {
    isComClicked: {
      type: Boolean,
    },
    activeId: {
      type: Number,
    },
  },
  name: "SlideContent",
  components: {
    PropertyContent,
  },

  methods: {
    toggleModal() {
      PropertyContent.methods.toggleModalParent();
      this.$emit("isOpen", false);
    },
    returnPropertyId(Id) {
      this.$emit("returnId", Id);
    },
  },
};
</script>
<style lang="scss" >
.closeBtn {
  position: fixed;
  right: 15px;
  top: 0;
  border-radius: 0px;
  z-index: 9;
  height: 50px;
  width: 50px;
  font-size: 2em;
  line-height: 0;
  margin: 0;
}
.outBox {
  width: 100%;
  height: 100%;
}
.image-list {
  img {
    width: 100%;
  }
}

.activeN {
  border-bottom: 2px solid #194583;
}
.child-content {
  clear: both;
  .child-title {
    font-weight: bold;
    font-size: 1.1em;
    border-left: 2px solid #194583;
    padding-left: 6px;
    margin-bottom: 1em;
  }
  .single-image {
    width: 25%;
    display: block;
    float: left;
    margin: 0 1.6rem 1.6rem 0;
  }
}

.property-loop-section {
  .property-component {
    .cards-title {
      font-size: 1.1em;
      font-weight: bold;
      border-bottom: 2px solid #194583;
      padding: 0 10px 5px 2px;
      display: inline-block;
    }
  }
}
</style>