<template>

  <div class="content-section">
    <header class="side-header">
      <h4 class="card-title cards-title px-0 mb-2">{{$t('facilityNav.facilityPrimary')}}</h4>
      <nav class="side-nav">
        <ul>
          <li
            v-for="item in allCategoryData"
            :key="item.propertyId.propertyId"
            @click="activeNavBtn(item.propertyId)"
            :class="item.propertyId==activeId && isInProp? 'activeN':'inactiveN'"
          >
            {{item.property}}
          </li>
        </ul>
      </nav>
    </header>
    <section class="side-content-section">
      <CCard class="my-3 border-white bg-light">
        <CCardBody>
          <div
            class="loopSection"
            v-if="allCatView && activeBodyContent"
          >
            <h4
              class="sub-title"
              v-if="activeBodyContent.title"
            >{{activeBodyContent.title}}</h4>
            <div
              v-if="activeBodyContent.description"
              v-html="activeBodyContent.description"
              class="white_space mb-2"
            />

            <ImageSlider
              clickSlider
              :images="activeBodyContent.images"
              :key="activeBodyContent.images"
              v-if="isComClicked"
            />
            <div
              class="child-content p-4"
              v-for="child in activeBodyContent.child"
              :key="child.id"
            >
              <h5
                class="child-title"
                v-if="child.title"
              >{{child.title}}</h5>

              <ImageSlider
                clickSlider
                :images="child.images"
                :key="child.images"
                v-if="isComClicked"
              />
              <!-- <pre><code>{{child.images}}</code></pre> -->
              <div
                v-if="child.description"
                v-html="child.description"
                class="white_space mb-2"
              />
            </div>
            <!-- child content end  -->

          </div>
          <div
            class="property-loop-section"
            v-if="isPropery"
          >
            <!-- <PropertyView /> -->
          </div>
          <div
            class="property-loop-section"
            v-if="isAccess"
          >
            <!-- <AccessViiew /> -->
          </div>
        </CCardBody>
      </CCard>
    </section>
    <!-- side header end  -->
  </div>
</template>

<script>
import axios from "axios";
import PROPERTYLIST from "@/mixins/property";

export default {
  mixins: [PROPERTYLIST],
  props: {
    isComClicked: {
      type: Boolean,
    },
    activeId: {
      type: Number,
    },
  },
  name: "PropertyContent",
  data() {
    return {
      isActiveNav: 0,
      filterActiveNav: 0,
      defActiveProperty: "",
      activeBodyContent: {
        title: "",
      },
      allCategoryData: [],
      allProperties: [],
      allCatView: false,
      isInProp: true,
      isPropery: false,
      isAccess: false,
      ofClicked: false,
    };
  },
  created() {
    this.UniqueId = this.$route.params.uniqueId;
  },
  beforeMount() {
    // this.getAct();
    // this.deleteMe();
    this.getHotelContentA();
  },
  methods: {
    /**
     * get hotel details
     */
    getHotelContentA() {
      this.allCatView = true;

      var urlRequest = "/rest/hotel/" + this.UniqueId + "/detail";
      axios
        .get(urlRequest, {
          headers: { "Accept-Language": this.$i18n.locale },
        })
        .then((resp) => {
          var allList = resp.data.details;

          // only below selected properties are been shown
          var selectedList = [
            this.PROPERTYLIST.recomendation,
            this.PROPERTYLIST.room,
            this.PROPERTYLIST.bathAndOnsen,
            this.PROPERTYLIST.food,
            this.PROPERTYLIST.equipmentServices,
            this.PROPERTYLIST.others,
          ];

          // match with selected property
          allList.forEach((a) =>
            selectedList.forEach((l, k) => {
              if (
                a.propertyId == l &&
                (a.description !== "" ||
                  (a.images !== undefined && a.images.length > 0))
              ) {
                a.viewOrder = k;
                this.allCategoryData.push(a);
              }
            })
          );
          // sort by oallCategoryDatarder
          this.allCategoryData.sort((a, b) => a.viewOrder - b.viewOrder);
          this.allCategoryData.forEach((a) => {
            a.child.sort((a, b) => a.displayOrder - b.displayOrder);
          });
          console.log("allCategoryData" + JSON.stringify(this.allCategoryData));
          this.getAct();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getAct() {
      this.ofClicked = this.isComClicked;
      this.activeId
        ? (this.isActiveNav = this.activeId)
        : (this.isActiveNav = this.allCategoryData.map((e) => e.propertyId)[0]);

      this.activeNavContent(this.isActiveNav);
    },

    activeNavContent(Id) {
      if (Id == 21 && this.isComClicked) {
        this.activeNavAcsBtn();
        var tId = this.allCategoryData.map((e) => e.propertyId)[0];
        this.activeBodyContent = this.allCategoryData.find(
          (e) => e.propertyId == tId
        );
        // this.activeId = this.allCategoryData.map((e) => e.propertyId)[0];
      } else {
        this.activeBodyContent = this.allCategoryData.find(
          (e) => e.propertyId == Id
        );
      }
    },
    activeNavBtn(Id) {
      // this.activeId = Id;
      // this.$emit("returnId", Id);
      this.$parent.returnPropertyId(Id)
      this.isPropery = false;
      this.isAccess = false;
      this.allCatView = true;
      this.isInProp = true;
    },
    activeNavBtnP() {
      // this.$emit("returnId", this.allCategoryData.map((e) => e.propertyId)[0]);
      this.$parent.returnPropertyId(this.allCategoryData.map((e) => e.propertyId)[0])

      // this.activeId = this.allCategoryData.map((e) => e.propertyId)[0];
      this.isPropery = true;
      this.allCatView = false;
      this.isInProp = false;
      this.isAccess = false;
    },
    activeNavAcsBtn() {
      this.isAccess = true;
      this.allCatView = false;
      this.isInProp = false;
      this.isPropery = false;
    },

    toggleModalParent() {
      console.log('close');
      this.ofClicked = false;
      this.isPropery = false;
      this.isAccess = false;
      this.allCatView = true;
      this.isInProp = true;
    },
  },

  updated() {
    if (this.activeId) {
      this.activeNavContent(this.activeId);
    }
  },
};
</script>
<style lang="scss" >
.closeBtn {
  position: fixed;
  right: 15px;
  top: 0;
  border-radius: 0px;
  z-index: 9;
  height: 50px;
  width: 50px;
  font-size: 2em;
  line-height: 0;
  margin: 0;
}
.outBox {
  width: 100%;
  height: 100%;
}
.image-list {
  img {
    width: 100%;
  }
}

.activeN {
  border-bottom: 2px solid #194583;
}
.child-content {
  clear: both;
  .child-title {
    font-weight: bold;
    font-size: 1.1em;
    border-left: 2px solid #194583;
    padding-left: 6px;
    margin-bottom: 1em;
  }
  .single-image {
    width: 25%;
    display: block;
    float: left;
    margin: 0 1.6rem 1.6rem 0;
  }
}

.property-loop-section {
  .property-component {
    .cards-title {
      font-size: 1.1em;
      font-weight: bold;
      border-bottom: 2px solid #194583;
      padding: 0 10px 5px 2px;
      display: inline-block;
    }
  }
}
</style>