<template>

  <div>
    <div class="imgGallery-section d-sm-down-none">

      <header class="side-header border-white ">
        <nav class="side-nav">
          <ul>
            <li
              v-for="item in allCatImg"
              :key="item.catId"
              @click="getImgByCategory(item.catId)"
              :class="item.catId==activeNavLk? 'activeN':'inactiveN'"
            >
              {{item.catName+" ("+item.total+")"}}
            </li>
          </ul>
        </nav>

      </header>
      <section>
        <div
          class="image_list"
          v-if="isImage"
        >
          <ImageSlider
            :images="sendImg"
            :key="reload"
          />

        </div>

        <div
          v-else
          class="empty_image"
        >
          <CAlert color="warning"> {{$t('imageGallery.noImage')}} </CAlert>
        </div>
      </section>
    </div>
    <div class="sp-img-gallery d-lg-none">
      <section>
        <div
          class="image_list"
          v-if="isImage"
        >
          <div
            class="img-list"
            v-for="item in spAllCatImg"
            :key="item.catName"
          >
            <h4 class="sub-title">{{item.catName+` (`+item.total+`)`}}</h4>
            <ul>
              <li
                v-for="img in  item.child"
                :key="img.fileName"
              >
                <img
                  :src="imgLg+img.fileName"
                  @click="openImgMdl(img)"
                >
              </li>
            </ul>
            <!-- <pre><code>{{allCatImg}}</code></pre> -->
          </div>
          <div
            class="singleImgModal"
            v-if="openSPModal"
          >

            <CButton
              color="danger"
              class="sp_close"
              @click="openSPModal = false"
            >
              X
            </CButton>
            <div class="overLay">
              <img :src="imgLg+singleSpImg.fileName">
              <p class="text-center text-white">{{singleSpImg.title}}</p>
            </div>
          </div>

        </div>

        <div
          v-else
          class="empty_image"
        >
          <CAlert color="warning"> {{$t('imageGallery.noImage')}} </CAlert>
        </div>
      </section>
    </div>
    <!-- sp image gallery section end  -->

  </div>
</template>

<script>
import axios from "axios";
import config from "@/config";

export default {
  name: "GalleryBody",

  data() {
    return {
      CatsList: [],
      catsImageList: [],
      allImg: [],
      sendImg: [],
      activeNavLk: 0,
      imgOg: config.IMAGE_URL, // orginal size
      imgLg: config.IMAGE_LG_URL, // large size
      imgMd: config.IMAGE_MD_URL, // medium size
      imgSm: config.IMAGE_SM_URL, // small size
      reload: 0,
      allCatImg: [],
      openSPModal: false,
      singleSpImg: {},
      test: [],
    };
  },
  created() {
    this.gUniqueId = this.$route.params.uniqueId;
  },
  computed: {
    isImage() {
      return this.catsImageList ? true : false;
    },
    spAllCatImg() {
      return this.allCatImg.slice(1);
    },
  },

  methods: {
    /**
     * get all imgaes
     *
     */
    getAllCatImg() {
      var urlRequest = "/rest/hotel/" + this.gUniqueId + "/image";
      axios
        .get(urlRequest, {
          headers: { "Accept-Language": this.$i18n.locale },
        })
        .then((resp) => {
          const imageList = resp.data.images;
          this.catsImageList = imageList.filter((a) => a.displayable);
          // console.log("getall image:" + JSON.stringify(this.catsImageList));
          this.catsImageList.forEach((element) => {
            this.allImg.push(element);
            this.sendImg.push(element);
          });
          // this.sendImg = this.allImg;

          // create and push all image category
          if (!this.allCatImg.some((a) => a.catId == 0)) {
            this.allCatImg.push({
              catId: 0,
              catName: this.$t("common.all"),
              total: this.catsImageList.length,
            });
          }
          // filter category from all images
          var cod = this.catsImageList.filter(
            (e, index) =>
              !this.catsImageList
                .map((o) => o.category)
                .includes(e.category, index + 1)
          );
          this.test = cod.map((e) => e.category);
          this.getAllCatsList();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    /**
     *  Get all category list
     */
    getAllCatsList() {
      var urlRequest = "/rest/imagecategory";
      axios
        .get(urlRequest, {
          headers: { "Accept-Language": this.$i18n.locale },
        })
        .then((resp) => {
          var allCategorys = resp.data.categories;
          this.CatsList.push({ value: 0, label: this.$t("common.all") });
          allCategorys.map((e) =>
            this.CatsList.push({
              value: e.id,
              label: e.name,
            })
          );
          this.CatsList.sort((a, b) => a.id - b.id);
          console.log("categoryList:" + JSON.stringify(this.CatsList));
          this.spAll(this.CatsList);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    /**
     * set before mounted
     *  */
    getDefImg(catID) {
      var urlRequest =
        "/rest/hotel/" + this.gUniqueId + "/image/category/" + catID;
      axios
        .get(urlRequest, {
          headers: { "Accept-Language": this.$i18n.locale },
        })
        .then((resp) => {
          const imageList = resp.data.images;

          this.catsImageList = imageList.filter((a) => a.displayable);

          this.catsImageList.forEach((element) => {
            this.sendImg.push(element);
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    /**
     * get all image by category order
     * */
    spAll(cat) {
      var sendCat = cat.filter((a) => this.test.some((k) => a.value == k));

      sendCat.forEach((e) => {
        this.SPgetDefImg(e.value, e.label);
      });
    },
    /**
     * set before mounted
     *  */
    SPgetDefImg(catID, label) {
      var urlRequest =
        "/rest/hotel/" + this.gUniqueId + "/image/category/" + catID;
      axios
        .get(urlRequest, {
          headers: { "Accept-Language": this.$i18n.locale },
        })
        .then((resp) => {
          const imageList = resp.data.images;

          var tData = imageList.filter((a) => a.displayable);

          // console.log("spDa**" + JSON.stringify(tData));
          var strl = {
            catName: label,
            catId: catID,
            child: [],
            total: "",
          };
          tData.forEach((k) => {
            strl.child.push({ fileName: k.fileName, title: k.title });
          });

          strl.total = strl.child.length;
          this.allCatImg.push(strl);
          this.allCatImg.sort((a, b) => a.catId - b.catId);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    /**
     * get selected category images
     * */
    getImgByCategory(event) {
      this.activeNavLk = event;
      this.sendImg = [];
      event == 0 ? (this.sendImg = this.allImg) : this.getDefImg(event);
      this.reload++;
    },
    /* sp open image modal
     */
    openImgMdl(img) {
      this.openSPModal = true;
      this.singleSpImg = img;
    },
  },
  beforeMount() {
    this.getAllCatImg();
    // this.deleteMes();
  },
  updated() {},
};
</script>
<style scoped>
.side-nav {
  background: #cccccc;
}
.side-nav ul li {
  display: inline-block;
  padding: 0.5rem 2rem;
  font-weight: bold;
  height: 50px;
}
.closeBtn {
  position: fixed;
  right: 15px;
  top: 0;
  border-radius: 0px;
  z-index: 9;
  height: 50px;
  width: 50px;
  font-size: 2em;
  line-height: 0;
  margin: 0;
}
.closeBtn-sm {
  position: fixed;
  right: 0px;
  top: 0;
  border-radius: 0px;
  z-index: 9;
  height: 40px;
  width: 40px;
  font-size: 1.6em;
  line-height: 0;
  margin: 0;
  text-align: center;
}
.opt-gallery-body {
  height: 100%;
  width: 100%;
  background: #ffffff;
  overflow: auto;
}
.imgGallery-section .side-header {
  height: 50px;
}
.sp-img-gallery {
  clear: both;
  display: block;
  margin-top: 0;
}
.sp-img-gallery h4 {
  margin-left: 1%;
}
.sp-img-gallery .img-list ul {
  margin-bottom: 10px;
}
.sp-img-gallery .img-list ul li {
  display: inline-block;
  width: 31.33%;
  margin: 1%;
}
.sp-img-gallery img {
  width: 100%;
  height: auto;
}
.singleImgModal {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 99999999;
  background: #5f5f5f;
  width: 100%;
  height: 100%;
}
.overLay {
  padding: 1rem;
  top: 2rem;
  position: relative;
}
.sp_close {
  right: 0;
  top: 0;
  position: fixed;
  border-radius: 0;
}
</style>