<template>
  <div class="property-component">

    <h2 class="cards-title card-h mb-3">{{$t('prepertyList.basicInfo')}}</h2>
    <!-- <pre><code>{{properties}}</code></pre> -->
    <!-- <pre><code>{{aboutAmenityProperty}}</code></pre> -->
    <div
      v-for="property in properties"
      :key="property.propertyId"
    >
      <CRow
        class="py-2 border-bottom-dashed px-0 property-row"
        v-if="property.description && (isOnsenView ?  true : property.propertyId !== 19 )"
      >
        <CCol md="3"><strong class="property-title">{{property.property}}
            <!-- <pre><code>{{property.propertyId}}</code></pre> -->
          </strong></CCol>
        <CCol>
          <!-- #region 支払方法 -->
          <template v-if="[7].includes(property.propertyId)">
            <template v-for="type in ['credit', 'ec', 'qr', 'other']">
              <template v-if="canShowPayment(property.description[type])">
                <strong :key="type">{{ type | paymentTypeLabel }}</strong>
                <div
                  v-if="property.description[type].list.length > 0"
                  v-html="paymentListString(type, property.description[type].list)"
                  class="white_space"
                  :key="type"
                ></div>
                <div
                  v-html="property.description[type].text"
                  class="white_space"
                  :key="type"
                ></div>
              </template>
            </template>
       
          </template>
          <!-- #endregion 支払方法 -->
          <!-- #region チェックイン時間 -->
          <template v-else-if="[42].includes(property.propertyId)">
            <div
              v-html="checkInTimeString(property.description.begin, property.description.end)"
              class="white_space"
            ></div>
            <div
              v-html="property.description.text"
              class="white_space"
            ></div>
          </template>
          <!-- #endregion チェックイン時間 -->
          <!-- #region チェックアウト時間 -->
          <template v-else-if="[43].includes(property.propertyId)">
            <div
              v-html="`チェックアウト時刻 : ${property.description.time}`"
              class="white_space"
            ></div>
            <div
              v-html="property.description.text"
              class="white_space"
            ></div>
          </template>
          <!-- #endregion チェックアウト時間 -->
          <!-- #region 朝食/夕食 -->
          <template v-else-if="[30, 31].includes(property.propertyId)">
            <!-- 朝食/夕食 -->
            <!-- TODO: listもtextもないときは項目自体を非表示にする -->
            <div
              v-if="property.description.list.length > 0"
              v-html="`食事場所 : ${mealLocationListString(property.description.list)}`"
              class="white_space"
            ></div>
            <div
              v-html="property.description.text"
              class="white_space"
            ></div>
          </template>
          <!-- #endregion 朝食/夕食 -->
          <!-- #region お風呂 -->
          <template v-else-if="[19].includes(property.propertyId)">
            <!-- お風呂 -->
            <ul class="parent-class">
              <li
                v-for="person in allOnsenList"
                :key="person.label"
              >
                <template v-if="isOnsenView">
                  <CRow
                    class="person-f p-0"
                    v-if="person.option"
                  >
                    <template v-if="!person.option.every((element) => element.person == null)">
                      <CCol class="pl-0">{{person.label}}</CCol>
                      <CCol class="p-0">
                        <CRow
                          v-for="gendar in person.option"
                          :key="gendar.label"
                        >
                          <template v-if="gendar.person">
                            <CCol class="p-0">{{gendar.label}}</CCol>
                            <CCol>{{gendar.person}}</CCol>
                          </template>
                        </CRow>
                      </CCol>
                    </template>
                  </CRow>
                </template>
              </li>
            </ul>
            <!-- <ul
              class="parent-class"
              v-if="isOnsenView"
            >
              <li
                v-for="(person,i) in bathStr(property.description)"
                :key="i"
              >
                <CRow
                  v-if="person[0]=='big'"
                  class="w-25"
                >
                  <CCol>大浴場 </CCol>
                  <CCol>
                    <ul class="person-gendar">
                      <li v-if="person[1].male && person[1].male!=='0'">男 {{person[1].male}}</li>
                      <li v-if="person[1].female && person[1].female!=='0'">女 {{person[1].female}}</li>
                    </ul>
                  </CCol>
                </CRow>
                <CRow
                  v-if="person[0]=='outdoor'"
                  class="w-25"
                >
                  <CCol>露天風呂</CCol>
                  <CCol>
                    <ul class="person-gendar">
                      <li v-if="person[1].male && person[1].male!=='0'">男 {{person[1].male}}</li>
                      <li v-if="person[1].female && person[1].female!=='0'">女 {{person[1].female}}</li>
                      <li v-if="person[1].mixed && person[1].mixed!=='0'">女 {{person[1].mixed}}</li>
                    </ul>
                  </CCol>
                </CRow>
                <CRow
                  v-if="person[0]=='reserved'"
                  class="w-25"
                >
                  <CCol>貸切風呂</CCol>
                  <CCol>
                    <ul class="person-gendar">
                      <li v-if="person[1] && person[1]!=='0'">{{person[1]}}</li>
                    </ul>
                  </CCol>
                </CRow>
              </li>
            </ul> -->

            <!-- <div
              v-html="bathString(property.description)"
              class="white_space"
            ></div>
            <div
              v-html="property.description.text"
              class="white_space"
            ></div> -->
          </template>
          <!-- #endregion お風呂 -->
          <!-- TODO: 総客室数の表示 -->
          <template v-else>
            <div
              v-html="property.description"
              class="white_space"
            ></div>
          </template>
        </CCol>
      </CRow>
    </div>

    <CRow
      class="border-bottom-dashed py-2  property-row"
      v-if="allAmenityList.length>0"
    >
      <CCol md="3"><strong class="property-title">{{$t('prepertyList.amenities')}}</strong></CCol>
      <CCol>
        <ul class="mb_list">
          <li
            v-for="amenity in allAmenityList"
            :key="amenity.id"
          >
            <span :class="amenity.enabled?'maru':'batsu'">{{amenity.name}}</span>

          </li>
        </ul>
      </CCol>
    </CRow>

    <CRow
      class="py-2 border-bottom-dashed px-0  property-row"
      v-if="aboutAmenityProperty && aboutAmenityProperty.description"
    >
      <CCol md="3"><strong class="property-title">{{aboutAmenityProperty.property}}</strong></CCol>
      <CCol>
        <div
          v-html="aboutAmenityProperty.description"
          class="white_space"
        ></div>
      </CCol>
    </CRow>

    <CRow
      class="border-bottom-dashed py-2  property-row"
      v-if="allFeatureList.length>0"
    >
      <CCol md="3"><strong class="property-title">{{$t('prepertyList.features')}}</strong></CCol>
      <CCol>
        <ul class="mb_list">
          <li
            v-for="feature in allFeatureList"
            :key="feature.id"
          >
            <span :class="feature.enabled?'maru':'batsu'">{{feature.name}}</span>

          </li>
        </ul>
      </CCol>
    </CRow>
    <CRow
      class="py-2 border-bottom-dashed px-0  property-row"
      v-if="aboutFacilityProperty && aboutFacilityProperty.description"
    >
      <CCol md="3"><strong class="property-title">{{aboutFacilityProperty.property}}</strong></CCol>
      <CCol>
        <div
          v-html="aboutFacilityProperty.description"
          class="white_space"
        ></div>
      </CCol>
    </CRow>
    <CRow
      class="py-2 border-bottom-dashed px-0  property-row"
      v-if="otherProperty && otherProperty.description"
    >
      <CCol md="3"><strong class="property-title">{{otherProperty.property}}</strong></CCol>
      <CCol>
        <div
          v-html="otherProperty.description"
          class="white_space"
        ></div>
      </CCol>
    </CRow>

  </div>
  <!-- property card end  -->
</template>

<script>
import axios from "axios";
import PROPERTYLIST from "@/mixins/property";

export default {
  mixins: [PROPERTYLIST],

  name: "PropertyView",

  data() {
    return {
      isOnsenView: false,
      allOnsenListData: [],
      allOnsenList: [],
      properties: [],
      allAmenityList: [],
      allFeatureList: [],
      aboutAmenityProperty: {},
      aboutFacilityProperty: {},
      otherProperty: {},

      // NOTE: src\main\webapp\src\views\operator\Facility\components\EditBasic.vue と値を同じにすること
      payment:
      {
        // クレジットカード
        credit: [
          { label: 'JCB', value: 0, enabled: false, },
          { label: 'VISA', value: 1, enabled: false, },
          { label: 'マスター', value: 2, enabled: false, },
          { label: 'AMEX', value: 3, enabled: false, },
          { label: 'UC', value: 4, enabled: false, },
          { label: 'DC', value: 5, enabled: false, },
          { label: 'NICOS', value: 6, enabled: false, },
          { label: 'ダイナース', value: 7, enabled: false, },
          { label: 'UFJ', value: 8, enabled: false, },
          { label: 'セゾン', value: 9, enabled: false, },
          { label: 'デビットカード', value: 10, enabled: false, },
          { label: '他クレジットカード', value: 11, enabled: false, },
        ],
        // 電子マネー
        ec: [
          { label: 'iD', value: 0, enabled: false, },
          { label: 'QUICPay', value: 1, enabled: false, },
          { label: 'Suica', value: 2, enabled: false, },
          { label: 'PASMO', value: 6, enabled: false, },
          { label: 'toICa', value: 7, enabled: false, },
          { label: 'ICOCA', value: 8, enabled: false, },
          { label: 'SUGOCA', value: 9, enabled: false, },
          // { label: 'WAON', value: 3, enabled: false, }, // 削除
          // { label: 'nanaco', value: 4, enabled: false, }, // 削除
          { label: '楽天Edy', value: 5, enabled: false, },
          { label: '他電子マネー決済', value: 99, enabled: false, },
        ],
        // QRコード決済
        qr: [
          { label: 'PayPay', value: 0, enabled: false, },
          { label: 'LINEPay', value: 1, enabled: false, },
          { label: '楽天Pay', value: 2, enabled: false, },
          // { label: 'Airペイ', value: 3, enabled: false, }, // NOTE: 削除
          // { label: 'AirペイQR', value: 4, enabled: false, }, // NOTE: 削除
          { label: 'd払い', value: 5, enabled: false, },
          { label: 'au PAY', value: 6, enabled: false, },
          { label: 'J-coin Pay', value: 7, enabled: false, },
          { label: 'Alipey+', value: 8, enabled: false, },
          { label: 'WeChat Pay', value: 9, enabled: false, },
          { label: 'UnionPay', value: 10, enabled: false, },
          { label: '他QRコード決済', value: 99, enabled: false, },
        ],
        
        other: [],
      },

      mealLocationList: [
        { label: "食事処（レストラン含）", value: 0, enabled: false },
        { label: "個室食", value: 1, enabled: false },
        { label: "部屋食", value: 2, enabled: false },
      ],
    };
  },

  created() {
    this.UniqueId = this.$route.params.uniqueId;
  },

  methods: {
    /**
     * get all selected Properties list
     */
    getAllPropertyInfo() {
      var urlRequest = "/rest/hotel/" + this.UniqueId + "/property";
      axios
        .get(urlRequest, {
          headers: { "Accept-Language": this.$i18n.locale },
        })
        .then((resp) => {
          var allList = resp.data.properties;
          // console.log("all list**" + JSON.stringify(allList));

          // only below selected properties are been shown
          var selectedList = [
            this.PROPERTYLIST.bathAndHotelTax,
            this.PROPERTYLIST.paymentMethod,
            this.PROPERTYLIST.checkInTime,
            this.PROPERTYLIST.checkOutTime,
            this.PROPERTYLIST.pets,
            this.PROPERTYLIST.children,
            this.PROPERTYLIST.breakfarst,
            this.PROPERTYLIST.dinner,
            this.PROPERTYLIST.lunch,
            this.PROPERTYLIST.roomServices,
            this.PROPERTYLIST.bath,
            this.PROPERTYLIST.aboutBath,
            this.PROPERTYLIST.hotSpringName,
            this.PROPERTYLIST.springAndEfficency,
            this.PROPERTYLIST.oneDayBath,
            this.PROPERTYLIST.bathingTime,
            this.PROPERTYLIST.poolInformation,
            this.PROPERTYLIST.bedRockBath,
            this.PROPERTYLIST.massage,
            this.PROPERTYLIST.fitnessGym,
            this.PROPERTYLIST.gymnasium,
            this.PROPERTYLIST.ground,
            this.PROPERTYLIST.dogRun,
            this.PROPERTYLIST.aboutFacility,
            this.PROPERTYLIST.totalRooms,
          ];
          // match with selected property
          // alert(JSON.stringify(this.properties));
          allList.forEach((a) => {
            selectedList.forEach((l, k) => {
              if (a.propertyId !== l) return;
              if (a.description === null || a.description === undefined) return;
              a.description = [7, 19, 30, 31, 42, 43].includes(a.propertyId)
                ? JSON.parse(a.description)
                : a.description || "";
              a.viewOrder = k; // assign order number according selectedList serial
              this.properties.push(a);
            });
          });
          // sort by order
          this.properties.sort((a, b) => a.viewOrder - b.viewOrder);
          this.properties.forEach((a) => {
            if (a.propertyId == this.PROPERTYLIST.totalRooms) {
              a.description = `総客室数 ${a.description} 室`;
            }
          });

          //get "aboutAmenity" property
          this.aboutAmenityProperty = allList.find(
            (a) => a.propertyId == this.PROPERTYLIST.aboutAmenity
          );
          //get "aboutFeature" property
          this.aboutFacilityProperty = allList.find(
            (a) => a.propertyId == this.PROPERTYLIST.aboutFeatures
          );
          //get "other" property
          this.otherProperty = allList.find(
            (a) => a.propertyId == this.PROPERTYLIST.others
          );
          var aboutOnsen = allList.find(
            (a) => a.propertyId == this.PROPERTYLIST.bath
          );
          this.bathSlt(aboutOnsen.description);
          console.log("*****" + JSON.stringify(aboutOnsen.description));
        })
        .catch((err) => {
          console.log(err);
        });
    },
    /**
     *  get all aminity and feature
     */
    getAmenityAndFeatureInfo() {
      var amenityUrlRequest = "/rest/amenity";
      axios
        .get(amenityUrlRequest, {
          headers: { "Accept-Language": this.$i18n.locale },
        })
        .then((resp) => {
          // this.allAmenityList = resp.data.amenities;
          this.getSelectedAmenity(resp.data.amenities);
        })
        .catch((err) => {
          console.log(err);
        });

      var featureUrlRequest = "/rest/feature";
      axios
        .get(featureUrlRequest, {
          headers: { "Accept-Language": this.$i18n.locale },
        })
        .then((resp) => {
          // this.allFeatureList = resp.data.features;
          this.getSelectedFeatures(resp.data.features);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    /**
     *  get all selected aminity
     */
    getSelectedAmenity(list) {
      var amenityUrlRequest = "/rest/hotel/" + this.UniqueId + "/amenity";
      axios
        .get(amenityUrlRequest, {
          headers: { "Accept-Language": this.$i18n.locale },
        })
        .then((resp) => {
          this.allAmenityList = list;
          var selectedAmenityList = resp.data.features;
          this.allAmenityList.forEach((e) => {
            selectedAmenityList.forEach((a) => {
              if (e.id == a.feature.id) {
                e.enabled = a.enabled;
              }
            });
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    /**
     *  get all selected feature
     */
    getSelectedFeatures(list) {
      var featureUrlRequest = "/rest/hotel/" + this.UniqueId + "/feature";
      axios
        .get(featureUrlRequest, {
          headers: { "Accept-Language": this.$i18n.locale },
        })
        .then((resp) => {
          this.allFeatureList = list;
          var selectedFeatrueList = resp.data.features;
          this.allFeatureList.forEach((e) => {
            selectedFeatrueList.forEach((a) => {
              if (e.id == a.feature.id) {
                e.enabled = a.enabled;
              }
            });
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // TODO: filter, property を使う
    checkInTimeString(begin, end) {
      if (begin.length > 0 && end.length > 0) {
        return `チェックイン開始時刻: ${begin} ～ 最終チェックイン時刻: ${end}`;
      }

      if (begin.length > 0) {
        return `チェックイン開始時刻: ${begin}`;
      }

      if (end.length > 0) {
        return `最終チェックイン時刻: ${end}`;
      }
    },

    // TODO: filter, property を使う
    paymentListString(type, list) {
      this.debugLog("paymentListString(type, list)");
      this.debugLog(type);
      let labels = this.payment[type]
        .filter((a) => list.includes(a.value))
        .map((a) => a.label);
      return labels.reduce((a, b) => `${a} / ${b}`);
    },

    // TODO: filter, property を使う
    mealLocationListString(list) {
      let mealLocationList = this.mealLocationList
        .filter((a) => list.includes(a.value))
        .map((a) => a.label);

      return mealLocationList.reduce((a, b) => `${a} / ${b}`);
    },

    bathSlt(data) {
      const arrayOfObj = Object.entries(data).map((e) => ({ [e[0]]: e[1] }));
      arrayOfObj.forEach((a) => {
        if (a.big || a.big == "") {
          a.label = "大浴場";
          a.option = Object.entries(a.big).map((e) => ({ [e[0]]: e[1] }));
          delete a.big;
        }
        if (a.outdoor || a.outdoor == "") {
          a.label = "露天風呂";
          a.option = Object.entries(a.outdoor).map((e) => ({ [e[0]]: e[1] }));
          delete a.outdoor;
        }
        if (a.reserved || a.reserved == "") {
          a.label = "貸切風呂";
          a.option = [{ reserved: a.reserved }];
          delete a.reserved;
        }

        this.allOnsenList.push(a);
      });
      var allValue = [];
      this.allOnsenList.forEach((a) => {
        a.option.forEach((b) => {
          b.male ? ((b.label = "男"), (b.person = b.male)) : b.male;
          b.female ? ((b.label = "女"), (b.person = b.female)) : b.female;
          b.mixed ? ((b.label = "混浴"), (b.person = b.mixed)) : b.mixed;
          b.reserved ? (b.person = b.reserved) : b.reserved;
          b.person == "0" ? (b.person = null) : b.person;
          allValue.push(b.person);
        });
      });
      this.isOnsenView = !allValue.every((element) => element == null);

      return this.allOnsenList;
    },

    canShowPayment(payment) {
      this.debugLog("canShowPayment(payment)");
      this.debugLog(payment);
      return payment?.list?.length > 0 || payment?.text;
    },
  },

  filters: {
    paymentTypeLabel(type){
      switch (type) {
        case 'credit': return "クレジットカード";
        case 'qr': return "QRコード決済";
        case 'ec': return "電子マネー決済";
        case 'other':  return "その他決済について";
        default:
          break;
      }
    }
  },

  beforeMount() {
    // this.deleteMe();
    this.getAllPropertyInfo();
    this.getAmenityAndFeatureInfo();
  },
};
</script>
<style scoped>
.row {
  margin-left: 0;
  margin-right: 0;
}
.person-f {
  width: 30%;
}
</style>

