<template>
  <CCard
    class="yado-near border-white px-0"
    v-if="isNearByFacility && nearbyFacilityList.length>0"
  >

    <CCardHeader
      borderColor="white"
      class="pb-0 px-0"
    >
      <CCardTitle class="cards-title card-h">近くの宿</CCardTitle>
    </CCardHeader>
    <CCardBody class="px-0">

      <ul class="nearby-card">
        <li
          class="border-white"
          v-for="hotel in nearbyFacilityList"
          :key="hotel.id"
          @click="goFacilityViewPage(hotel.id)"
        >
          <div
            class="top-section"
            v-lazy:background-image="imgMd+hotel.thumnail"
          ></div>
          <div class="name-section">
            <strong>{{hotel.name}}</strong>
          </div>
        </li>
      </ul>
    </CCardBody>
  </CCard>
</template>

<script>
import axios from "axios";
import config from "@/config";


export default {
  name: "NearbyFacility",
  data() {
    return {
      isNearByFacility: false,
      nearbyFacilityList: [],
      UniqueId: "",
      imgMd: config.IMAGE_MD_URL, // medium size

    };
  },

  created() {
    this.UniqueId = this.$route.params.uniqueId;
  },

  methods: {
    /**
     * get all Properties list
     */
    getAllProperties() {
      var urlRequest = `/rest/hotel/${this.UniqueId}/ranking/distance`;
      axios
        .get(urlRequest, {
          headers: { "Accept-Language": this.$i18n.locale },
        })
        .then((resp) => {
          console.log("Nearbyfacility:" + JSON.stringify(resp.data));
          this.nearbyFacilityList = resp.data.distanseHotels;
          this.nearbyFacilityList.sort((a, b) => a.rank - b.rank);
          this.nearbyFacilityList = this.nearbyFacilityList.slice(0, 5);
          this.isNearByFacility = true;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    /** go to hotel detail page */
    goFacilityViewPage(uniqueId) {
      window.location.href = `/facility/${uniqueId}`;
    },
  },
  beforeMount() {
    this.getAllProperties();
  },
};
</script>
<style lang="scss">
.yado-near {
  .nearby-card {
    display: flex;
    flex-flow: row wrap;

    li {
      width: 18.4%;
      border: 1px solid #d8dbe0;
      border-radius: 0.25rem;
      cursor: pointer;
      margin: 0 1% 2%;

      .top-section {
        width: 100%;
        padding-top: 56.25%;
        background-position: center;
        background-size: cover;
        position: relative;
      }

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }

      .name-section {
        padding: 0.5rem 1rem;

        strong {
          font-size: 1em;
        }
      }
    }
  }
}
</style>